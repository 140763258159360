'use strict';

(function () {
  var self;
  class AddEditNoteModalCtrl {
    constructor($scope, $uibModalInstance, Site, User, Util, Organization, options, usSpinnerService, Contact, Notes, Upload, Image, toastr) {
      this.Util = Util;
      this.Site = Site;
      this.User = User;
      this.Contact = Contact;
      this.Notes = Notes;
      this.Upload = Upload;
      this.Image = Image;
      this.toastr = toastr;
      this.Organization = Organization;
      this.usSpinnerService = usSpinnerService;
      this.$uibModalInstance = $uibModalInstance;
      this.isDisabled = false;
      this.errors = {};
      this.options = options;
      this.submitted = false;
      this.popup = {
        opened: false
      };
      self = this;
      if(this.options.contacts) {
        this.contact = angular.copy(this.options.contacts);
        this.contacts = this.contact.sort((a,b) => a.firstName.localeCompare(b.firstName));
      }
      if (this.options.note) {
        this.note = angular.copy(this.options.note);
        this.mode = 'UPDATE';
      } else {
        this.mode = 'CREATE';
        this.note = {};
      }
      if(this.options.organization) {
        this.organization = angular.copy(this.options.organization);
      }

      $scope.$on('$stateChangeStart', function () {
        $uibModalInstance.dismiss();
      });

    }

    saveNote(form) {
        this.submitted = true;
        this.serverError = null;
        if (!this.note.description) {
          this.errors = {
            description: 'Please enter description'
          };
          return;
        }
        if (form.$valid) {
          this.isDisabled = true;
          this.startSpin('spinner-1');
            this.note.filesPathToInsert = [];
            this.startFilesUpload(this.note.files, (err, result) => {
              if (!err) {
                this.note.files = this.note.filesPathToInsert;
                this.note.organization = this.organization.id;
                delete this.note.filesPathToInsert;
                this.Notes.save(this.note).$promise
                .then(note => {
                    this.stopSpin('spinner-1');
                    console.log(note);
                    this.$uibModalInstance.close(note);
                })
                .catch(err => {
                    this.isDisabled = false;
                    this.errors = {};
                    this.stopSpin('spinner-1');
                    this.handleError(err, form);
                });
              } else {
                console.log('error');
                this.toastr.error('Files upload failed.');
              }
          })   
        }
    }

    startFilesUpload(files, done) {
      async.eachSeries(files, (file, callback) => {
        this.uploadFile(file, (err, filePath) => {
          if (!err) {
            this.note.filesPathToInsert.push({ name: file.name ,path: filePath} );
            callback();
          } else {
            console.log('upload file failed:', err);
            callback(err);
          }
        });
      }, err => {
        if (err) {
          console.log('A file failed to upload');
          done(err);
        } else {
          done();
        }
      });
    }

    uploadFile(file, cb) {
        if (!file || !file.name) {
          cb({ success: false });
        }
        var filename = file.name;
        var type = file.type;
        var query = {
          filename: filename,
          type: type,
          resourceData: {
            resourceId: this.organization.id,
            resourceType : 'clients'
          },
        };
        this.isUploading = true;
        this.Image.signingResource({}, query).$promise
          .then(result => {
            this.Upload.upload({
              url: result.url, //s3Url
              transformRequest: function (data, headersGetter) {
                var headers = headersGetter();
                delete headers.Authorization;
                return data;
              },
              fields: result.fields, //credentials
              method: 'POST',
              file: file
            }).progress((evt) => {
              this.uploadProgress = parseInt(100.0 * evt.loaded / evt.total);
            }).success((data, status, headers, config) => {
              this.isUploading = false;
              // file is uploaded successfully
              cb(null, result.url + '/' + result.fields.key);
            }).error((err) => {
              this.isUploading = false;
              console.log('err', err);
              this.toastr.success('File was not uploaded to cloud. please try again later or contact administrator');
              cb(err);
            });
          });
      }

    openFollowUpDate() {
        this.popup.opened = true;
      }

    handleError(err, form) {
        if (err && err.data && err.data.meta && err.data.meta.error_message) {
          if (err.data.meta.code && err.data.meta.code != 422 && !this.Util.isArray(err.data.meta.error_message)) {
            this.serverError = err.data.meta.error_message || 'Internal Server Error';
          } else {
            err.data.meta.error_message.forEach(errorMesssage => {
              for (let fieldName in errorMesssage) {
                if (form[fieldName]) {
                  form[fieldName].$setValidity('mongoose', false);
                  this.errors[fieldName] = errorMesssage[fieldName];
                }
              }
            })
          }
        } else if (err.data && err.data.message) {
          this.serverError = err.data.message;  
          console.log('validation err: 1', err);
        } else {
          this.serverError = 'Internal Server Error';
          console.log('validation err: 2', err);
        }
      }

    cancelSave() {
      this.$uibModalInstance.dismiss('cancel');
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .controller('AddEditNoteModalCtrl', AddEditNoteModalCtrl);
})();